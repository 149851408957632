// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
}
// REMIX HMR END

import { cssBundleHref } from '@remix-run/css-bundle';
import Header from './layout/shell/Header';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';
import styles from './assets/styles/tailwind.css';
export const links = () => [{
  rel: 'stylesheet',
  href: styles
}, ...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: cssBundleHref
}] : [])];
export const meta = () => {
  return [{
    title: 'IOL | News that Connects South Africans'
  }, {
    name: 'description',
    content: 'Discover the world of IOL, bringing you a mix of current news across politics, sport, business, motoring and lifestyle. Telling your stories and driving change!'
  }];
};
export default function App() {
  return <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body>
        <Header />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;