// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/layout/shell/Header.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/layout/shell/Header.jsx");
  import.meta.hot.lastModified = "1689865683791.938";
}
// REMIX HMR END

import { Fragment, useState } from 'react';
import { NavLink } from '@remix-run/react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon, PuzzlePieceIcon, CloudIcon, ChartBarIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { longToday } from '../../lib/dateTools';
import iol from '../../assets/images/iol.svg';
import iolMobile from '../../assets/images/iol-mobile.svg';
import facebook from '../../assets/images/facebook.svg';
import twitter from '../../assets/images/twitter.svg';
const navigation = [{
  name: 'Shop @ Loot',
  href: 'https://www.loot.co.za/welcome?utm_source=iollink&utm_medium=textlink&utm_campaign=iolnavbarshopatlootlink'
}, {
  name: 'Personal Finance',
  href: '/personal-finance'
}, {
  name: 'Isolezwe',
  href: 'https://www.isolezwe.co.za/'
}, {
  name: 'Daily Voice',
  href: 'https://www.dailyvoice.co.za/'
}, {
  name: 'IOL Property',
  href: 'https://www.iolproperty.co.za/'
}];
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function Index() {
  _s();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return <header className='w-full bg-white shadow-[0_1px_3px_0_rgba(0,0,0,0.15)]'>
      <div className='w-full bg-gray-800 hidden sm:block'>
        <div className='mx-auto max-w-7xl flex items-center py-[6px] flex gap-[30px]'>
          {navigation.map(item => <NavLink key={item.name} to={item.href} className='transition-all ease-linear delay-150 text-white text-[12px] uppercase hover:text-gray-300' target='_blank'>
              {item.name}
            </NavLink>)}
        </div>
      </div>
      <Disclosure as='nav' className='bg-white px-[15px]'>
        {({
        open
      }) => <>
            <div className='mx-auto max-w-7xl py-[12px] sm:border-b border-gray-200'>
              <div className='flex items-center sm:justify-between w-full gap-[15px] relative'>
                <div className='flex items-center'>
                  <NavLink to='/'>
                    <img className='block h-[42px] w-[42px] lg:hidden' src={iolMobile} alt='IOL' />
                    <img className='hidden h-[32px] w-auto lg:block' src={iol} alt='IOL' />
                  </NavLink>
                </div>
                <div className='flex items-center justify-center max-w-[360px] w-full'>
                  <form action='/search' method='get' className='flex items-center justify-center w-full'>
                    <input type='search' placeholder='Search IOL' name='q' className='border-y border-l border-r-0 border-gray-400 rounded-l-[6px] px-[10px] py-[8px] w-[calc(100%-42px)] text-[16px] text-black-300 placeholder:text-gray-400' />
                    <button className='rounded-r-[6px] bg-zinc-600 hover:bg-red-700 transition-all ease-linear delay-150 p-[10px]'><MagnifyingGlassIcon className='w-[22px] h-[22px] text-white' /></button>
                  </form>
                </div>
                <div className='hidden lg:flex flex-col flex-shrink-0 items-end justify-center gap-[4px]'>
                  <p className='text-[12px] uppercase'>{longToday()}</p>
                  <div className='flex items-center gap-[5px]'>
                    <NavLink to='/my-notifications'><BellIcon className='h-[24px] text-zinc-600' /></NavLink>
                    <NavLink to='/puzzles'><PuzzlePieceIcon className='h-[24px] text-zinc-600' /></NavLink>
                    <NavLink to='/news/weather'><CloudIcon className='h-[24px] text-zinc-600' /></NavLink>
                    <NavLink to='/business-report/market-indicators'><ChartBarIcon className='h-[24px] text-zinc-600' /></NavLink>
                    <a href='https://www.facebook.com/IOLnews' target='_blank' rel='noreferrer'><img src={facebook} className='h-[24px] text-zinc-600' alt='' /></a>
                    <a href='https://www.twitter.com/IOL' target='_blank' rel='noreferrer'><img src={twitter} className='h-[24px] text-zinc-600' alt='' /></a>
                  </div>
                </div>
                <div className='relative inset-y-0 right-0 flex items-center sm:hidden'>
                  {/* Mobile menu button */}
                  <button onClick={() => setMobileMenuOpen(true)} className='group inline-flex items-center justify-center rounded-[6px] p-2 text-white-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                    <span className='sr-only'>Open main menu</span>
                    {open ? <XMarkIcon className='block h-6 w-6' aria-hidden='true' /> : <Bars3Icon className='block h-6 w-6' aria-hidden='true' />}
                  </button>
                </div>
              </div>
            </div>
            <div className='mx-auto max-w-7xl py-[12px] hidden lg:block'>
              <div className='relative flex items-center justify-between'>
                <div className='mx-auto w-full'>
                  <div className='hidden sm:block'>
                    <div className='flex space-x-4 w-full justify-between'>
                      <Menu as='div' className='relative'>
                        <Menu.Button className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                          News
                          <ChevronDownIcon className='ml-2 -mr-1 h-5 w-5 text-black-300 group-hover:rotate-180' aria-hidden='true' />
                        </Menu.Button>
                        <Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
                          <Menu.Items className='absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  South Africa
                                </a>}
                            </Menu.Item>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  Politics
                                </a>}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <Menu as='div' className='relative'>
                        <Menu.Button className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                          Lifestyle
                          <ChevronDownIcon className='ml-2 -mr-1 h-5 w-5 text-black-300 group-hover:rotate-180' aria-hidden='true' />
                        </Menu.Button>
                        <Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
                          <Menu.Items className='absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  South Africa
                                </a>}
                            </Menu.Item>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  Politics
                                </a>}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <Menu as='div' className='relative'>
                        <Menu.Button className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                          Business
                          <ChevronDownIcon className='ml-2 -mr-1 h-5 w-5 text-black-300 group-hover:rotate-180' aria-hidden='true' />
                        </Menu.Button>
                        <Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
                          <Menu.Items className='absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  South Africa
                                </a>}
                            </Menu.Item>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  Politics
                                </a>}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <Menu as='div' className='relative'>
                        <Menu.Button className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                          Sport
                          <ChevronDownIcon className='ml-2 -mr-1 h-5 w-5 text-black-300 group-hover:rotate-180' aria-hidden='true' />
                        </Menu.Button>
                        <Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
                          <Menu.Items className='absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  South Africa
                                </a>}
                            </Menu.Item>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  Politics
                                </a>}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <NavLink to='' className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                        Opinion
                      </NavLink>
                      <NavLink to='' className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                        IOL TV
                      </NavLink>
                      <Menu as='div' className='relative'>
                        <Menu.Button className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                          More
                          <ChevronDownIcon className='ml-2 -mr-1 h-5 w-5 text-black-300 group-hover:rotate-180' aria-hidden='true' />
                        </Menu.Button>
                        <Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
                          <Menu.Items className='absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  South Africa
                                </a>}
                            </Menu.Item>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  Politics
                                </a>}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <Menu as='div' className='relative'>
                        <Menu.Button className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                          Features
                          <ChevronDownIcon className='ml-2 -mr-1 h-5 w-5 text-black-300 group-hover:rotate-180' aria-hidden='true' />
                        </Menu.Button>
                        <Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
                          <Menu.Items className='absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  South Africa
                                </a>}
                            </Menu.Item>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  Politics
                                </a>}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <Menu as='div' className='relative group hover:bg-gray-100 rounded-[6px]'>
                        <Menu.Button className='relative inline-flex items-center justify-center group text-black-300 hover:bg-gray-100 rounded-[6px] px-[10px] py-[8px] text-[16px] font-medium'>
                          Newspapers
                          <ChevronDownIcon className='ml-2 -mr-1 h-5 w-5 text-black-300 group-hover:rotate-180' aria-hidden='true' />
                        </Menu.Button>
                        <Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
                          <Menu.Items className='absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  South Africa
                                </a>}
                            </Menu.Item>
                            <Menu.Item className='text-black-300 hover:bg-gray-700 hover:text-white'>
                              {({
                            active
                          }) => <a href='#' className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                  Politics
                                </a>}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <NavLink to='' className='text-white bg-red-700 hover:bg-gray-600 rounded-[6px] px-[10px] py-[8px] text-[16px] uppercase font-medium transition-all ease-linear delay-150'>
                        Subscribe
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Dialog as='div' className='lg:hidden' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              <div className='fixed inset-0 z-10' />
              <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-[15px] sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
                <div className='flex items-center justify-between'>
                  <a href='#' className='-m-1.5 p-1.5'>
                    <span className='sr-only'>IOL</span>
                    <img className='h-8 w-auto' src={iol} alt='' />
                  </a>
                  <button type='button' className='-m-2.5 rounded-md p-2.5 text-gray-700' onClick={() => setMobileMenuOpen(false)}>
                    <span className='sr-only'>Close menu</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='mt-6 flow-root'>
                  <div className='-my-6 divide-y divide-gray-500/10'>
                    <div className='space-y-2 py-6'>
                      <Disclosure as='div' className='-mx-3'>
                        {({
                      open
                    }) => <>
                            <Disclosure.Button className='flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                              News
                              <ChevronDownIcon className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')} aria-hidden='true' />
                            </Disclosure.Button>
                            <Disclosure.Panel className='mt-2 space-y-2'>
                              {[...navigation].map(item => <NavLink key={item.name} as='a' href={item.href} className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                  {item.name}
                                </NavLink>)}
                            </Disclosure.Panel>
                          </>}
                      </Disclosure>
                      <Disclosure as='div' className='-mx-3'>
                        {({
                      open
                    }) => <>
                            <Disclosure.Button className='flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                              Lifestyle
                              <ChevronDownIcon className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')} aria-hidden='true' />
                            </Disclosure.Button>
                            <Disclosure.Panel className='mt-2 space-y-2'>
                              {[...navigation].map(item => <NavLink key={item.name} as='a' href={item.href} className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                  {item.name}
                                </NavLink>)}
                            </Disclosure.Panel>
                          </>}
                      </Disclosure>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </>}
      </Disclosure>
    </header>;
}
_s(Index, "d7gXMF6mPDUhHBNUSEb8mLK4AII=");
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;