// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/dateTools.js"
);
import.meta.hot.lastModified = "1689865683795.0288";
}
// REMIX HMR END

export function longToday () {
  const today = new Date()
  if (typeof today.toLocaleDateString === 'function') {
    return today.toLocaleDateString('en-ZA', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })
  } else {
    return today.toDateString()
  }
}
